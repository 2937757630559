import React, { Component } from "react"
import Action from './action'

class Stage extends Component {
  state = {
    executions: [],
    isLoading: true,
    isError: false,
    searchQuery: "",
  }

  render() {
    const p = this.props.data;
    const time = new Date(p.time)
    const out = time.toUTCString()


    var actions = []
    if (p.action_updates !== undefined) {
      for (const [key, value] of Object.entries(p.action_updates)) {
        value['name'] = key
        actions.push(value)
      }
    }
  
    actions = actions.sort((a, b) => {
      if (a.time === b.time) {
        return (a.state === "SUCCEEDED") ? -1 : 0
      }
      return (a.time < b.time) ? -1 : ((a.time > b.time) ? 1 : 0);
    });

    var statusColor = "text-gray-700"
    switch (p.state) {
      case "SUCCEEDED":
        statusColor = "text-green-700"
        break;
      case "FAILED":
        statusColor = "text-red-700"
        break;
      case "RESUMED":
      case "STARTED":
        statusColor = "text-blue-700"
        break;
      default:
        break;
    }
    
    return (
      <div class="m-4">
        <div class="grid grid-cols-3">
          <div class="text-lg">{p.name}</div> 
          <div class={`text-sm ${statusColor}`}>{p.state}</div>
          <div class="font-mono text-gray-600 text-xs">{out}</div> 
        </div>
        
        {actions.map((p) => {     
          return (
            <Action data={p}/>
          )
        })}
      </div>
    )
  }
}


export default Stage
