import React, { Component } from "react"
import Pipeline from '../components/pipeline'
import Auth from '../components/auth'
import lipstick from "../images/lipstick.png"

const axios = require('axios');

function initialSinceValue() {
  const oneDayAgo = new Date(new Date().getTime() - (24 * 3600 * 1000));
  return oneDayAgo.toISOString().slice(0, 16);
}

const refreshMilliseconds = 20000
const debounceMilliseconds = 500


class Pipelines extends Component {

  state = {
    executions: [],
    interval: null,
    searchQuery: "",
    sinceQuery: initialSinceValue(),
  }

  async componentDidMount() {
    this.refresh();
  }

  handleSearchChange = (event) => {
    this.setState({searchQuery: event.target.value });
  }

  handleSinceChange = (event) => {
    clearInterval(this.state.interval);
    this.setState({
      sinceQuery: event.target.value,
      interval: setInterval(this.refresh, debounceMilliseconds),
   });
  }

  refresh = () => {
    clearInterval(this.state.interval)
    const token = sessionStorage.getItem('accessToken')

    if (token === null){
      return
    }

    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const after = `after=${encodeURIComponent(this.state.sinceQuery)}`
    axios.get(`${process.env.GATSBY_ROADHOG_API}/pipelines?limit=500&${after}`, config)
      .then(result => {
        const data = result.data.sort((a, b) => {
          return (a.time > b.time) ? -1 : ((a.time < b.time) ? 1 : 0);
        });
        this.setState({
          executions: data,
          interval: setInterval(this.refresh, refreshMilliseconds),
        });
      })
      .catch(err => {
        sessionStorage.clear();
        window.location.reload();
      });
  }

  render() {
    var displayed = this.state.executions;
    if(this.state.searchQuery) {
      displayed = []
      const s = this.state.searchQuery.toLowerCase()
      for (const ex of this.state.executions) {
        if (
          ex["pipeline"].toLowerCase().includes(s) ||
          ex["state"].toLowerCase().includes(s)
        ){
          displayed.push(ex)
        }
      }
    }

    return (
      <Auth>
        <title>Pipelines</title>
        <div class="m-2">
          <nav class="h-24">
            <div class="flex flex-row">
              <img class="w-24 h-24 h-auto" src={lipstick}/>
              <p class="hidden md:text-3xl m-auto md:inline">Pipelines</p>
              <div class="flex-auto" /> 
              <p class="text-xl font-light m-auto">Since:</p>
              <input class="ml-1 m-auto border-2 border-sky-500 rounded-md" type="datetime-local" value={this.state.sinceQuery} onChange={this.handleSinceChange}></input>
              <p class="text-xl font-light m-auto">Search:</p>
              <input class="ml-1 m-auto border-2 border-sky-500 rounded-md" value={this.state.searchQuery} onChange={this.handleSearchChange}></input>
            </div>
          </nav>
        </div>
        {displayed.map((p) => {     
          return (<Pipeline data={p} key={p["execution-id"]}/>)
        })}
      </Auth>
    )
  }
}

export default Pipelines
