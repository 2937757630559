import React, { Component } from "react"

class Auth extends Component {
  state = {
    authenicated: false
  }

  componentDidMount() {
    const fragment = window.location.hash.slice(1)
    const token = sessionStorage.getItem('accessToken')


    if(token !== null){     
      console.log("you are logged in")
      if (window.location.pathname === "/") {
        console.log("redirect to pipelines")
        window.location = "/pipelines"
      }
      this.setState({ authenicated: true })
    } else if (fragment.includes("access_token")) {
      const fragmentParams = new URLSearchParams(fragment);
      sessionStorage.setItem('accessToken', fragmentParams.get('access_token'))
      window.location = fragmentParams.get('state')   
    } else {
      const host = window.location.hostname
      const redirect = process.env.GATSBY_AUTH_REDIRECT || "https://" + encodeURI(host)
      
      const tail = `?client_id=${process.env.GATSBY_APP_CLIENT_ID}&response_type=token&redirect_uri=` + redirect
      const state = "&state=" + encodeURI(window.location.href)

      console.log(process.env.GATSBY_LOGIN_URL + "/login" + tail + state)
      window.location.assign(process.env.GATSBY_LOGIN_URL + "/login" + tail + state);
    }
  }

  render() {
    return (
      <div>
        {this.state.authenicated ? this.props.children: null}
      </div>
    )
  }
}

export default Auth
