import React, { Component } from "react"

class Action extends Component {
  state = {
    executions: [],
    isLoading: true,
    isError: false,
    searchQuery: "",
  }

  render() {
    const p = this.props.data;
    const time = new Date(p.time)
    const out = time.toUTCString()

    var statusColor = "text-gray-700"
    switch (p.state) {
      case "SUCCEEDED":
        statusColor = "text-green-700"
        break;
      case "FAILED":
        statusColor = "text-red-700"
        break;
      case "RESUMED":
      case "STARTED":
        statusColor = "text-blue-700"
        break;
      default:
        break;
    }

    return (
      <div class="ml-4 grid grid-cols-3 gap-2 text-xs">
        <div>{p.name}</div> 
        <div class={`${statusColor}`}>{p.state}</div>
        <div class="font-mono text-gray-600">{out}</div> 
      </div>
    )
  }
}

export default Action
