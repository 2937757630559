import React, { Component } from "react"
import Stage from './stage'

class Pipeline extends Component {
  state = {
    executions: [],
    showDetails: false,
    isLoading: true,
    isError: false,
    searchQuery: "",
  }

  handleClick = () => {
    this.setState(prevState => ({
      showDetails: !prevState.showDetails
    }));
  }


  render() {
    const p = this.props.data;

    var stages = []
    for (const [key, value] of Object.entries(p.stage_updates)) {
      value['name'] = key
      stages.push(value)
    }
  
    stages = stages.sort((a, b) => {
      if (a.time === b.time) {
        return (a.state === "SUCCEEDED") ? -1 : 0
      }
      return (a.time < b.time) ? -1 : ((a.time > b.time) ? 1 : 0);
    });
    
    const time = new Date(p.time)
    const time_formatted = time.toUTCString()

    var detials = (<div/>)
    if (this.state.showDetails) {
      detials = stages.map((p) => {     
        return (
          <Stage data={p}/>
        )
      })
    }

    var name = p.pipeline

    name = name.replace("stacklet-", "")
    name = name.replace("-codepipeline", "")

    var bgColor = "bg-gray-100 hover:bg-gray-300"
    var statusColor = "text-gray-700"

    switch (p.state) {
      case "SUCCEEDED":
        bgColor = "bg-green-200 hover:bg-green-300 border-green-500"
        statusColor = "text-green-700"
        break;
      case "FAILED":
        bgColor = "bg-red-200 hover:bg-red-300 border-red-500"
        statusColor = "text-red-700"
        break;
      case "RESUMED":
      case "STARTED":
        bgColor = "bg-blue-200 hover:bg-blue-300 border-blue-500 animate-pulse"
        statusColor = "text-blue-700"
        break;
      default:
        break;
    }

    return (
      <div class={`m-2 p-2 border-l-4 rounded-tr-lg rounded-br-lg ${bgColor}`} state={p.state} onClick={this.handleClick}>
        <div class="flex flex-row">
          <div class="text-2xl font-bold uppercase">{name}</div> 
          <div class={`ml-4 m-auto ${statusColor}`}>{p.state}</div>
          <div class="flex-auto" /> 
        </div>
        <div class="flex flex-wrap font-mono text-gray-600 text-sm">
          <div class="ml-4"><b>Execution:</b> {p['execution-id']}</div>
          <div class="ml-4"><b>Account:</b> {p.account}</div>
          <div class="ml-4"><b>Region:</b> {p.region}</div>
          <div class="ml-4"><b>Time:</b> {time_formatted}</div>
        </div>
        {detials}
      </div>
    )
  }
}

export default Pipeline
